import { create } from "zustand";
import {
  AutoApproveFlagTable,
  MaxIncDecTable,
  MinMaxPriceTable,
} from "../interfaces/IControlConfig";
import { IStore, ProductInfo } from "../interfaces/IStore";
import { IControlPreviewChanges } from "../interfaces/IControlPreviewChanges";

// ControlState interface
interface ControlState {
  globalSelectedStores: IStore[];
  selectedProduct: ProductInfo;
  originalMinMaxPrice: MinMaxPriceTable[]; // Original data
  editedMinMaxPrice: MinMaxPriceTable[]; // Edited data
  originalMaxIncDec: MaxIncDecTable[]; // Original data
  editedMaxIncDec: MaxIncDecTable[]; // Edited data
  originalAutoApproveFlagData: AutoApproveFlagTable[]; // Original data
  editedAutoApproveFlagData: AutoApproveFlagTable[]; // Edited data
  editedMinMaxRowIds: string[];
  editedMinMaxRowMeta: {};
  editedMaxIncDecRowMeta: {};
  editedAutoApprovalRowMeta: {};
  editedMaxIncRowIds: string[];
  globalMinMaxPriceValues: {};
  globalMaxIncDecValues: {};
  globalAutoApprovalValues: {};
  previewScreenData: IControlPreviewChanges;
  error: string | null;
  isLoading: boolean;
  isLoadingStore: boolean;
  isSubmitting: boolean;
  openPreviewScreen: boolean;
  globalValidationErrors: {};
  inEditMode: {
    [key: string]: boolean;
  };
  setSelectedProduct: (product: ProductInfo) => void;
  setPreviewScreenData: (previewScreenData: IControlPreviewChanges) => void;
  setGlobalSelectedStores: (stores: IStore[]) => void;
  setMinMaxPriceData: (data: MinMaxPriceTable[]) => void;
  setMinMaxPriceEditedRowId: (idList: string[]) => void;
  setMaxIncData: (data: MaxIncDecTable[]) => void;
  setAutoApproveFlagData: (data: AutoApproveFlagTable[]) => void;
  setMaxIncDecEditedRowId: (idList: string[]) => void;
  setGlobalMinMaxPriceFieldValues: (field: string, value: any) => void;
  setGlobalMaxIncDecFieldValues: (field: string, value: any) => void;
  setGlobalAutoApprovalFieldValues: (field: string, value: any) => void;
  updateMinMaxPrice: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateMaxIncDec: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  updateAutoApproval: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) => void;
  revertAllGlobalMinMaxPriceFieldValues: () => void;
  revertAllGlobalMaxIncDecFieldValues: () => void;
  revertAllGlobalAutoApproveFieldValues: () => void;
  revertMinMaxPriceChanges: () => void;
  revertMaxIncDecChanges: () => void;
  revertAutoApprovalChanges: () => void;
  revertAllChanges: () => void;
  setError: (error: string | null) => void;
  setLoading: (isLoading: boolean) => void;
  setIsLoadingStores: (isLoading: boolean) => void;
  setIsSubmitting: (flag: boolean) => void;
  setOpenPreviewScreen: (falg: boolean) => void;
  setInEditMode: (flag: boolean, tabId: string) => void;
  resetStore: () => void;
  resetControlData: () => void;
}

// Create Zustand store
const useControlStore = create<ControlState>((set) => ({
  originalMinMaxPrice: [],
  editedMinMaxPrice: [],
  originalMaxIncDec: [],
  originalAutoApproveFlagData: [],
  editedMinMaxRowIds: [],
  editedMinMaxRowMeta: {},
  editedMaxIncDecRowMeta: {},
  editedAutoApprovalRowMeta: {},
  editedMaxIncDec: [],
  editedAutoApproveFlagData: [],
  editedMaxIncRowIds: [],
  globalMinMaxPriceValues: {},
  globalMaxIncDecValues: {},
  globalAutoApprovalValues: {},
  error: null,
  isLoading: false,
  isLoadingStore: true,
  isSubmitting: false,
  openPreviewScreen: false,
  globalSelectedStores: [],
  selectedProduct: {
    id: undefined,
    name: undefined,
  },
  previewScreenData: {},
  globalValidationErrors: {},
  inEditMode: {},
  setPreviewScreenData: (data: IControlPreviewChanges) =>
    set({ previewScreenData: data }),
  setSelectedProduct: (data) => set({ selectedProduct: data }),
  setGlobalSelectedStores: (data) => set({ globalSelectedStores: data }),
  setIsLoadingStores: (flag: boolean) => set({ isLoadingStore: flag }),
  setMinMaxPriceData: (data) =>
    set({ originalMinMaxPrice: data, editedMinMaxPrice: data }),
  setMinMaxPriceEditedRowId: (idList) => set({ editedMinMaxRowIds: idList }),
  setMaxIncData: (data) =>
    set({ originalMaxIncDec: data, editedMaxIncDec: data }),
  setAutoApproveFlagData: (data) =>
    set({ originalAutoApproveFlagData: data, editedAutoApproveFlagData: data }),
  setMaxIncDecEditedRowId: (idList) => set({ editedMaxIncRowIds: idList }),
  setGlobalMinMaxPriceFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalMinMaxPriceValues;
      const currentGlobalError = state.globalValidationErrors;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      // Perform validation checks
      const minPrice = Number(current["min_price_new"]);
      const maxPrice = Number(current["max_price_new"]);

      // Clear errors initially for both fields to ensure updated checks
      delete currentGlobalError["max_price_new"];
      delete currentGlobalError["min_price_new"];

      if (minPrice !== undefined && maxPrice !== undefined) {
        // Validate that max price is not less than min price
        if (maxPrice < minPrice) {
          if (field === "max_price_new") {
            currentGlobalError["max_price_new"] =
              "Max Price cannot be less than Min Price.";
          } else if (field === "min_price_new") {
            currentGlobalError["min_price_new"] =
              "Min Price cannot be greater than Max Price.";
          }
        } else if (maxPrice === minPrice) {
          if (field === "max_price_new") {
            currentGlobalError["max_price_new"] =
              "Max Price cannot be equal to Min Price.";
          } else if (field === "min_price_new") {
            currentGlobalError["min_price_new"] =
              "Min Price cannot be equal to Max Price.";
          }
        }
      }
      return {
        globalMinMaxPriceValues: current,
        globalValidationErrors: currentGlobalError,
      };
    }),
  setGlobalMaxIncDecFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalMaxIncDecValues;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      return {
        globalMaxIncDecValues: current,
      };
    }),
  setGlobalAutoApprovalFieldValues: (field: string, value: any) =>
    set((state) => {
      const current = state.globalAutoApprovalValues;
      if (value === undefined || value === null || value === "") {
        delete current[field];
      } else {
        current[field] = value;
      }
      return {
        globalAutoApprovalValues: current,
      };
    }),
  updateMinMaxPrice: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) =>
    set((state) => {
      const updatedData = state.editedMinMaxPrice.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedMinMaxRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedMinMaxPrice: updatedData,
        editedMinMaxRowMeta: currentEditedMeta,
      };
    }),

  updateMaxIncDec: (id: string, updatedRow: any, changedProperties: string[]) =>
    set((state) => {
      const updatedData = state.editedMaxIncDec.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedMaxIncDecRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedMaxIncDec: updatedData,
        editedMaxIncDecRowMeta: currentEditedMeta,
      };
    }),
  updateAutoApproval: (
    id: string,
    updatedRow: any,
    changedProperties: string[]
  ) =>
    set((state) => {
      const updatedData = state.editedAutoApproveFlagData.map((row) =>
        row.id === id ? { ...row, ...updatedRow } : row
      );

      let currentEditedMeta = state.editedAutoApprovalRowMeta;
      if (changedProperties.length === 0) {
        delete currentEditedMeta[id];
      } else {
        if (!currentEditedMeta[id]) {
          currentEditedMeta = {
            ...currentEditedMeta,
            [id]: changedProperties,
          };
        } else {
          currentEditedMeta[id] = changedProperties;
        }
      }
      return {
        editedAutoApproveFlagData: updatedData,
        editedAutoApprovalRowMeta: currentEditedMeta,
      };
    }),
  revertMinMaxPriceChanges: () =>
    set((state) => ({ editedMinMaxPrice: state.originalMinMaxPrice })),
  revertAllGlobalMinMaxPriceFieldValues: () =>
    set((state) => ({ globalMinMaxPriceValues: {} })),
  revertAllGlobalMaxIncDecFieldValues: () =>
    set((state) => ({ globalMaxIncDecValues: {} })),
  revertAllGlobalAutoApproveFieldValues: () =>
    set((state) => ({ globalAutoApprovalValues: {} })),
  revertMaxIncDecChanges: () =>
    set((state) => ({ editedMaxIncDec: state.originalMaxIncDec })),
  revertAllChanges: () =>
    set((state) => {
      return {
        globalMinMaxPriceValues: {},
        globalMaxIncDecValues: {},
        globalAutoApprovalValues: {},
        editedMinMaxPrice: state.originalMinMaxPrice,
        editedMaxIncDec: state.originalMaxIncDec,
        editedAutoApproveFlagData: state.originalAutoApproveFlagData,
        editedAutoApprovalRowMeta: {},
        editedMinMaxRowMeta: {},
        editedMaxIncDecRowMeta: {},
      };
    }),
  revertAutoApprovalChanges: () =>
    set((state) => ({
      editedAutoApproveFlagData: state.originalAutoApproveFlagData,
    })),
  setError: (error) => set({ error }),

  setLoading: (isLoading) => set({ isLoading }),
  setIsSubmitting: (flag) => set({ isSubmitting: flag }),
  setInEditMode: (flag, tabId) =>
    set((state) => {
      if (!flag) {
        return { inEditMode: {} };
      } else {
        return { inEditMode: { [tabId]: flag } };
      }
    }),
  setOpenPreviewScreen: (flag) =>
    set({
      originalMinMaxPrice: [],
      editedMinMaxPrice: [],
      originalMaxIncDec: [],
      originalAutoApproveFlagData: [],
      editedMinMaxRowIds: [],
      editedMinMaxRowMeta: {},
      editedMaxIncDecRowMeta: {},
      editedAutoApprovalRowMeta: {},
      editedAutoApproveFlagData: [],
      editedMaxIncDec: [],
      editedMaxIncRowIds: [],
      globalMinMaxPriceValues: {},
      globalMaxIncDecValues: {},
      globalAutoApprovalValues: {},
      error: null,
      isLoading: false,
      isLoadingStore: true,
      isSubmitting: false,
      openPreviewScreen: flag,
      globalSelectedStores: [],
      selectedProduct: {
        id: undefined,
        name: undefined,
      },
    }),
  resetStore: () =>
    set({
      originalMinMaxPrice: [],
      originalAutoApproveFlagData: [],
      editedMinMaxPrice: [],
      originalMaxIncDec: [],
      editedAutoApprovalRowMeta: {},
      editedAutoApproveFlagData: [],
      editedMinMaxRowIds: [],
      editedMinMaxRowMeta: {},
      editedMaxIncDecRowMeta: {},
      editedMaxIncDec: [],
      editedMaxIncRowIds: [],
      globalMinMaxPriceValues: {},
      globalMaxIncDecValues: {},
      globalAutoApprovalValues: {},
      error: null,
      isLoading: false,
      isLoadingStore: true,
      isSubmitting: false,
      openPreviewScreen: false,
      globalSelectedStores: [],
      selectedProduct: {
        id: undefined,
        name: undefined,
      },
      previewScreenData: {},
    }),
  resetControlData: () =>
    set((state) => ({
      // Reset data-related states while preserving store selection and product
      originalMinMaxPrice: [],
      originalAutoApproveFlagData: [],
      editedMinMaxPrice: [],
      editedAutoApproveFlagData: [],
      originalMaxIncDec: [],
      editedMaxIncDec: [],
      editedMinMaxRowIds: [],
      editedMaxIncRowIds: [],
      editedMinMaxRowMeta: {},
      editedAutoApprovalRowMeta: {},
      editedMaxIncDecRowMeta: {},
      globalMinMaxPriceValues: {},
      globalMaxIncDecValues: {},
      globalAutoApprovalValues: {},
      error: null,
      isLoading: true, // Set to true as we're about to fetch new data
      isSubmitting: false,
      openPreviewScreen: false,
      previewScreenData: {},
      // Don't reset globalSelectedStores and selectedProduct
      globalSelectedStores: state.globalSelectedStores,
      selectedProduct: state.selectedProduct,
    })),
}));

export default useControlStore;
