import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import RootComponent from "./components/RootComponent";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { Buffer } from "buffer";

window.Buffer = Buffer;

const queryClient = new QueryClient();

const storeIds = [
  "37326",
  "33116",
  "34997",
  "36717",
  "35981",
  "30455",
  "12200",
  "1028",
  "38156",
  "37207",
  "36483",
  "40159",
  "47040",
  "36774",
  "37114",
  "41927",
  "32772",
  "41928",
  "41929",
  "38671",
  "214455",
  "32879",
  "24382",
  "21445",
  "32983",
  "27737",
  "32924",
  "34749",
  "41155",
  "32917",
  "32919",
  "38277",
];

// uncomment for DEV
// const storeIds = ["38156"];

const subscribeMock = (callback) => {
  const mockActionCardProps = {
    listviewSelectedStores: storeIds,
    extractedStoreCount: storeIds.length,
  };

  setTimeout(() => {
    callback(mockActionCardProps);
  }, 1000);

  return {
    unsubscribe: () => {},
  };
};

const mockCustomProps = {
  compRef: {
    mapLayerService: {
      openMultiStoreControlConfigWindow$: {
        subscribe: subscribeMock,
      },
    },
  },
  compRefClose: () => console.debug("Mock close function called"),
};

const isLocalhost = window.location.hostname.includes("localhost");

if (window.location.href.includes("https://visops.7-eleven.com")) {
  window.console.debug = function () {};
}
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  //@ts-ignore
  rootComponent: isLocalhost
    ? (props) => (
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <RootComponent customProps={mockCustomProps} {...props} />
          </SnackbarProvider>
        </QueryClientProvider>
      )
    : //@ts-ignore
      ({ customProps }) => (
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <RootComponent customProps={customProps} />
          </SnackbarProvider>
        </QueryClientProvider>
      ),
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount, update } = lifecycles;
