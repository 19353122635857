import SelectionPanel from "./SelectionPanel";
import { Box, Button, Chip, Typography, Paper } from "@mui/material";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useEffect, useRef, useState } from "react";
import DataPanel from "./DataPanel";
import InfoPanel from "./InfoPanel";
import SETabs from "../core/SETabs";
import {
  controlTabTableCols,
  globalInputConfigs,
  validationTabs,
} from "../constants/config";
import { IStore, ProductInfo } from "../interfaces/IStore";
import renderGlobalInputField from "./renderGlobalInputField";
import useControlStore from "../store/useStoreControl";
import { isEqual, isMatch } from "lodash";
import { GridValidRowModel, useGridApiRef } from "@mui/x-data-grid";
import { getChangedProperties } from "../utils/app-utils";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";

interface AppContentProps {
  selectedStoreIdList?: string[];
  isMaximized?: boolean;
  selectedProduct?: ProductInfo;
  setSelectedProduct: (product: ProductInfo | undefined) => void;
}

const AppContent: React.FC<AppContentProps> = ({
  selectedStoreIdList = [],
  isMaximized = false,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [validationTabValue, setValidationTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValidationTabValue(newValue);
  };
  const {
    globalSelectedStores,
    originalMaxIncDec,
    originalMinMaxPrice,
    originalAutoApproveFlagData,
    globalMinMaxPriceValues,
    globalMaxIncDecValues,
    globalAutoApprovalValues,
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    editedAutoApprovalRowMeta,
    inEditMode,
    revertMinMaxPriceChanges,
    revertMaxIncDecChanges,
    revertAutoApprovalChanges,
    revertAllGlobalMaxIncDecFieldValues,
    revertAllGlobalAutoApproveFieldValues,
    revertAllGlobalMinMaxPriceFieldValues,
    setGlobalMinMaxPriceFieldValues,
    setGlobalMaxIncDecFieldValues,
    setGlobalAutoApprovalFieldValues,
    updateMinMaxPrice,
    updateMaxIncDec,
    updateAutoApproval,
    globalValidationErrors,
  } = useControlStore();

  const handleGlobalInputChange = (field, value) => {
    const tabId = validationTabs[validationTabValue].id;
    if (tabId === "minMaxPrice") {
      setGlobalMinMaxPriceFieldValues(field, value);
      if (!globalValidationErrors?.[field]) {
        applyGlobalChanges(tabId, globalMinMaxPriceValues);
      } else {
        const current = { ...globalMinMaxPriceValues };
        delete current[field];
        applyGlobalChanges(tabId, current);
      }
    } else if (tabId == "maxIncDec") {
      setGlobalMaxIncDecFieldValues(field, value);
      applyGlobalChanges(tabId, globalMaxIncDecValues);
    } else {
      setGlobalAutoApprovalFieldValues(field, value);
      applyGlobalChanges(tabId, globalAutoApprovalValues);
    }
  };

  const getTabGlobalFieldValue = (tabId, field) => {
    if (tabId === "minMaxPrice") {
      return globalMinMaxPriceValues[field];
    } else if (tabId === "maxIncDec") {
      return globalMaxIncDecValues[field];
    } else if (tabId === "autoApproval") {
      return globalAutoApprovalValues[field];
    }
    return "";
  };

  const isApplyChangesEnabled = (tabId) => {
    if (tabId === "minMaxPrice") {
      return Object.keys(globalMinMaxPriceValues).length > 0;
    } else if (tabId === "maxIncDec") {
      return Object.keys(globalMaxIncDecValues).length > 0;
    } else if (tabId === "autoApproval") {
      return Object.keys(globalAutoApprovalValues).length > 0;
    }
    return false;
  };
  const getControlDataOriginalRow = (tabId: string, id: string) => {
    if (tabId === "minMaxPrice") {
      return originalMinMaxPrice.find((val) => val.id === id);
    } else if (tabId === "maxIncDec") {
      return originalMaxIncDec.find((val) => val.id === id);
    } else {
      return originalAutoApproveFlagData.find((val) => val.id === id);
    }
  };

  const processRowUpdate = React.useCallback(
    (
      newRow: GridValidRowModel,
      oldRow?: GridValidRowModel
    ): GridValidRowModel => {
      const tabId = validationTabs[validationTabValue].id;
      const originalRow = getControlDataOriginalRow(tabId, newRow.id);
      const changedProperties = getChangedProperties(newRow, originalRow);
      if (tabId === "minMaxPrice") {
        updateMinMaxPrice(newRow.id, newRow, changedProperties);
      } else if (tabId == "maxIncDec") {
        updateMaxIncDec(newRow.id, newRow, changedProperties);
      } else {
        updateAutoApproval(newRow.id, newRow, changedProperties);
      }
      return newRow;
    },
    [
      validationTabValue,
      originalMinMaxPrice,
      originalMaxIncDec,
      originalAutoApproveFlagData,
    ]
  );

  const applyGlobalChanges = (tabId, globalValues) => {
    if (tabId === "minMaxPrice") {
      originalMinMaxPrice.forEach((row) => {
        const newRow = {
          ...row,
          ...globalValues,
        };
        processRowUpdate(newRow);
      });
    } else if (tabId == "maxIncDec") {
      originalMaxIncDec.forEach((row) => {
        const newRow = {
          ...row,
          ...globalValues,
        };
        processRowUpdate(newRow);
      });
    } else {
      originalAutoApproveFlagData.forEach((row) => {
        const newRow = {
          ...row,
          ...globalValues,
        };
        processRowUpdate(newRow);
      });
    }
  };

  const dicardChanges = (tabId) => {
    if (tabId === "minMaxPrice") {
      revertMinMaxPriceChanges();
      revertAllGlobalMinMaxPriceFieldValues();
      originalMinMaxPrice.forEach((row) => processRowUpdate(row));
    } else if (tabId === "maxIncDec") {
      revertMaxIncDecChanges();
      revertAllGlobalMaxIncDecFieldValues();
      originalMaxIncDec.forEach((row) => processRowUpdate(row));
    } else if (tabId == "autoApproval") {
      revertAutoApprovalChanges();
      revertAllGlobalAutoApproveFieldValues();
      originalAutoApproveFlagData.forEach((row) => processRowUpdate(row));
    }
  };

  const badgeCountObject = () => {
    return {
      minMaxPrice: Object.keys(editedMinMaxRowMeta).length,
      maxIncDec: Object.keys(editedMaxIncDecRowMeta).length,
      autoApproval: Object.keys(editedAutoApprovalRowMeta).length,
    };
  };
  return (
    <>
      <Box display="flex" flexDirection="row" gap={2}>
        <SelectionPanel
          selectedStoreIdList={selectedStoreIdList}
          isMaximized={isMaximized}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      </Box>
      {selectedProduct?.id ? (
        <>
          <InfoPanel />
          {/* Global Input Fields */}
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            gap={2}
            sx={{ mb: 3 }}
          >
            {controlTabTableCols[validationTabs[validationTabValue].id]
              .filter((col) => col.editable)
              .map((col) => {
                const tabId = validationTabs[validationTabValue].id;
                const {
                  label,
                  type,
                  options,
                  default_val,
                  min,
                  max,
                  step = "1",
                } = globalInputConfigs?.[tabId]?.[col.field];
                return (
                  <Box flex={1} minWidth="150px" key={`box - ${col.field}`}>
                    {renderGlobalInputField({
                      label,
                      default_val,
                      field: col.field,
                      handleChange: handleGlobalInputChange,
                      options,
                      type,
                      value: getTabGlobalFieldValue(tabId, col.field),
                      min,
                      max,
                      step,
                      globalValidationErrors,
                    })}
                  </Box>
                );
              })}
            <Box
              display="flex"
              justifyContent="flex-end"
              gap={1}
              sx={{ mb: 2 }}
            >
              <Button
                color="warning"
                variant="contained"
                size="medium"
                disabled={
                  !isApplyChangesEnabled(validationTabs[validationTabValue].id)
                }
                onClick={() =>
                  dicardChanges(validationTabs[validationTabValue].id)
                }
                sx={{
                  height: "56px",
                }}
              >
                Discard Changes
              </Button>
            </Box>
          </Box>
          {/* Flexbox Layout for Tabs and DataGrid */}
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="flex-start"
            gap={2}
            sx={{ mt: 1 }}
          >
            <Box flex="1 0 auto" width="100%">
              <DataPanel
                selectedStores={globalSelectedStores.map(
                  (store) => store?.seiid
                )}
                selectedProduct={selectedProduct?.id}
                tabId={validationTabs[validationTabValue].id}
                processRowUpdate={processRowUpdate}
              >
                <SETabs
                  tabs={validationTabs}
                  tabValue={validationTabValue}
                  handleTabChange={handleTabChange}
                  badgeCounts={badgeCountObject()}
                  tabEditMode={inEditMode}
                />
              </DataPanel>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "16px",
            margin: "8px",
            height: "100px",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <ProductionQuantityLimitsIcon
              sx={{
                fontSize: "32px",
                color: "#9e9e9e",
                marginBottom: "8px",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                color: "#424242",
                marginBottom: "4px",
                fontWeight: 500,
                fontSize: "1rem",
              }}
            >
              No Product Selected
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#757575",
                maxWidth: "400px",
                lineHeight: 1.4,
              }}
            >
              Please select a product from the dropdown menu above.
            </Typography>
          </Box>
        </Box>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default AppContent;
