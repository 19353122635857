import constants from "../constants/constants.json";

export function getChangedProperties(newRow: any, originalRow: any): string[] {
  const changedProperties: string[] = [];

  // Iterate over the keys of the newRow
  for (const key in newRow) {
    // Check if the key exists in originalRow and the values are different
    if (newRow.hasOwnProperty(key) && newRow[key] != originalRow[key]) {
      changedProperties.push(key);
    }
  }

  return changedProperties;
}

export function getUserName() {
  return sessionStorage.getItem(constants.USER_NAME_KEY) || "UNKNOWN USER";
}
