import React from "react";
import { Box, Typography, Button } from "@mui/material";

interface ErrorDisplayProps {
  message: string;
  onRetry?: () => void; // Optional retry function
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ message, onRetry }) => {
  return (
    <Box
      sx={{
        border: "1px solid red",
        borderRadius: "4px",
        padding: "16px",
        backgroundColor: "#ffe6e6",
        color: "#d8000c",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        An Error Occurred
      </Typography>
      <Typography variant="body1" gutterBottom>
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorDisplay;
