import React from "react";
import useControlStore from "../store/useStoreControl";
import SEDialog from "../core/SEDialog";
import {
  Badge,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { validationTabs } from "../constants/config";

const ConfirmationModal = ({
  isOpen,
  setConfirmationOpen,
  patchControlConfigChanges,
  isUnsavedChanges,
}) => {
  const {
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    editedAutoApprovalRowMeta,
    revertAllChanges,
  } = useControlStore();

  const getChangeCount = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta).length;
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta).length;
    } else if (tabId === "autoApproval") {
      return Object.keys(editedAutoApprovalRowMeta).length;
    }
    return 0;
  };
  return (
    <SEDialog
      open={isOpen}
      title={isUnsavedChanges ? "You Have Unsaved Changes!" : "Save changes?"}
      onClose={() => setConfirmationOpen(false)}
      footer={[
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{ width: "20%" }}
          onClick={patchControlConfigChanges}
        >
          Submit
        </Button>,
        isUnsavedChanges && (
          <Button
            onClick={() => {
              revertAllChanges();
              setConfirmationOpen(false);
            }}
            variant="contained"
            color="warning"
            size="small"
          >
            Dicard All
          </Button>
        ),
        <Button
          onClick={() => setConfirmationOpen(false)}
          variant="outlined"
          color="error"
          size="small"
        >
          Cancel
        </Button>,
      ]}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "medium", color: "text.secondary" }}
      >
        Summary of Changes
      </Typography>

      <List sx={{ mt: 1 }}>
        {validationTabs.map((tab, index) => {
          const changeCount = getChangeCount(tab.id);
          return (
            changeCount > 0 && (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                  bgcolor: "background.default",
                  borderRadius: 2,
                  mb: 1,
                }}
              >
                <ListItemText primary={tab.name} />
                <Chip
                  label={`${
                    changeCount > 1
                      ? changeCount + " changes"
                      : changeCount + " change"
                  }`}
                  color="warning"
                  sx={{
                    fontSize: "0.875rem",
                    height: "24px",
                    borderRadius: "12px",
                    padding: "0 8px",
                  }}
                />
              </ListItem>
            )
          );
        })}
      </List>
    </SEDialog>
  );
};

export default ConfirmationModal;
