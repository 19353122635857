import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";
import SERangeValidationTextField from "../core/SERangeValidationTextField";

// Define the function to render global input fields based on column configurations
const renderGlobalInputField = ({
  label,
  type,
  options,
  default_val,
  value,
  handleChange,
  field,
  min,
  max,
  step,
  globalValidationErrors,
}) => {
  switch (type) {
    case "singleSelect":
      return (
        <FormControl fullWidth variant="outlined">
          <InputLabel
            id={`${field}-label`}
            sx={{
              whiteSpace: "nowrap", // Prevent label from wrapping
              overflow: "hidden", // Hide overflow
              textOverflow: "ellipsis", // Show ellipsis for overflow
              maxWidth: "230px", // Set a max width for the label
            }}
          >{`Select ${label}`}</InputLabel>
          <Select
            labelId={`${field}-label`}
            value={value || ""} // Ensure value is an empty string if no selection
            onChange={(e) => handleChange(field, e.target.value)}
            label={`Select ${label}`} // Ensure the label is associated with the select
            onFocus={() =>
              (document.getElementById(`${field}-label`).style.maxWidth =
                "150px")
            }
            onBlur={() =>
              (document.getElementById(`${field}-label`).style.maxWidth =
                "230px")
            }
          >
            <MenuItem value="">
              <em>{default_val}</em>{" "}
              {/* Default value displayed when no selection */}
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value.toString()} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {globalValidationErrors?.[field] || " "}
          </FormHelperText>
        </FormControl>
      );

    case "number":
      return (
        <TextField
          key={field}
          label={label}
          type="number"
          error={Boolean(globalValidationErrors?.[field])}
          helperText={globalValidationErrors?.[field] || " "}
          value={value !== undefined && value !== null ? value : ""}
          onChange={(e) => {
            let inputValue = e.target.value;

            // Allow empty input
            if (inputValue === "") {
              handleChange(field, null); // Set to null or handle it as needed
              return;
            }

            // Validate decimal places
            const parts = inputValue.split(".");
            if (parts.length === 2 && parts[1].length > 3) {
              return; // Do not call handleChange if decimal places exceed 3
            }

            const newValue = Number(inputValue);
            if (newValue >= 0) {
              handleChange(field, newValue);
            }
          }}
          onBlur={(e) => {
            const rawValue = e.target.value;
            if (rawValue === "") {
              // Do nothing for empty values
              return;
            }

            const formattedValue = Number(rawValue).toFixed(3); // Format to three decimal places
            if (rawValue !== formattedValue) {
              handleChange(field, formattedValue); // Call handleChange only if reformatting is needed
            }
          }}
          slotProps={{ input: { inputProps: { step: step, min: 0 } } }}
          fullWidth
        />
      );
    case "range":
      return (
        <SERangeValidationTextField
          field={field}
          handleChange={handleChange}
          label={label}
          max={max}
          min={min}
          value={value}
          key={field}
        />
      );

    default:
      // Fallback for text or other types
      return (
        <TextField
          key={field}
          label={`Global ${label}`}
          value={value || ""}
          onChange={(e) => handleChange(field, e.target.value)}
          fullWidth
        />
      );
  }
};

export default renderGlobalInputField;
