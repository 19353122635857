import * as XLSX from "sheetjs-style"; // Use sheetjs-style for styling support
import { IControlPreviewChanges } from "../interfaces/IControlPreviewChanges";
import { sheetHeaderMapping } from "../constants/config";
export const exportToExcel = (data: IControlPreviewChanges) => {
  const workbook = XLSX.utils.book_new();

  // Iterate through each key in the data to create a separate sheet
  for (const [sheetName, sheetData] of Object.entries(data)) {
    // Transform nested objects for export, flattening each attribute's 'before' and 'after' values
    const formattedData = sheetData.map((item) => {
      const flattenedItem = { ...item };
      // Flatten each nested attribute
      for (const [key, value] of Object.entries(item)) {
        if (
          value &&
          typeof value === "object" &&
          "before" in value &&
          "after" in value
        ) {
          flattenedItem[sheetHeaderMapping[key].before] = value.before;
          flattenedItem[sheetHeaderMapping[key].after] = value.after;
          // Remove the original nested object
          delete flattenedItem[key];
        } else {
          flattenedItem[sheetHeaderMapping[key]] = value;
          delete flattenedItem[key];
        }
      }
      return flattenedItem;
    });

    console.log(formattedData);

    // Convert formatted data to a sheet
    const sheet = XLSX.utils.json_to_sheet(formattedData);

    // Apply formatting to 'after' values
    const range = XLSX.utils.decode_range(sheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const header = XLSX.utils.encode_col(C) + "1";
      if (sheet[header] && sheet[header].v.includes("After")) {
        for (let R = 2; R <= range.e.r + 1; ++R) {
          const cellAddress = XLSX.utils.encode_cell({ c: C, r: R - 1 });
          if (sheet[cellAddress]) {
            // Apply bold font style and background color to 'after' cells
            sheet[cellAddress].s = {
              font: { bold: true },
              fill: { fgColor: { rgb: "FFFF99" } }, // Yellow background color
            };
          }
        }
      }
    }
    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
  }

  const timestamp = new Date()
    .toISOString()
    .replace(/[:.-]/g, "_")
    .slice(0, 19);
  // Export the workbook
  XLSX.writeFile(workbook, `Bulk_Changes_Consolidated_${timestamp}.xlsx`);
};
