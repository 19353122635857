import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RestoreIcon from "@mui/icons-material/Restore";
import StoreIcon from "@mui/icons-material/Store";
import SaveIcon from "@mui/icons-material/Save";
import useControlStore from "../store/useStoreControl";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { exportToExcel } from "../utils/excelExportUtil";
import CloseIcon from "@mui/icons-material/Close";

const AppHeader = ({
  toggleMaximize,
  isMaximized,
  openStoreModal,
  setConfirmationOpen,
  showChips,
  closeApp,
  setIsUnsavedChanges,
}) => {
  const {
    globalSelectedStores,
    editedMaxIncDecRowMeta,
    editedMinMaxRowMeta,
    editedAutoApprovalRowMeta,
    revertAllChanges,
    previewScreenData,
    globalValidationErrors,
  } = useControlStore();
  const isChangesApplied = () => {
    return (
      (Object.keys(editedMaxIncDecRowMeta).length > 0 ||
        Object.keys(editedMinMaxRowMeta).length > 0 ||
        Object.keys(editedAutoApprovalRowMeta).length > 0) &&
      !(Object.keys(globalValidationErrors)?.length > 0)
    );
  };
  const discardAllChanges = () => {
    console.log("Discard all");
    revertAllChanges();
  };

  const submitChanges = () => {
    setConfirmationOpen(true);
  };

  const handleClose = () => {
    if (isChangesApplied()) {
      setConfirmationOpen(true);
      setIsUnsavedChanges(true);
    } else {
      closeApp();
    }
  };

  const exportPrevewDataTExcel = () => {
    exportToExcel(previewScreenData);
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      sx={{
        cursor: "move",
        userSelect: "none",
        backgroundColor: "#f1f9f1",
        borderBottom: "1px solid #e0e9e0",
        padding: "8px 16px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        minHeight: "48px",
        "&:hover": {
          backgroundColor: "#e8f4e8",
        },
        background: "linear-gradient(to bottom, #f8fcf8, #f1f9f1)",
        boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#1a73e8",
            fontSize: "1.2rem",
            letterSpacing: "0.2px",
          }}
        >
          Multi-Store Control Adjustments
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        {showChips ? (
          <>
            <Chip
              color="primary"
              icon={<StoreIcon />}
              label={`${globalSelectedStores.length} Stores`}
              onClick={() => openStoreModal(true)}
              variant="outlined"
              size="small"
              sx={{
                borderRadius: "4px",
                height: "28px",
              }}
            />
            <Chip
              color="warning"
              icon={<RestoreIcon />}
              disabled={!isChangesApplied()}
              onClick={discardAllChanges}
              label="DISCARD ALL"
              size="small"
              sx={{
                borderRadius: "4px",
                height: "28px",
              }}
            />
            <Chip
              color="success"
              disabled={!isChangesApplied()}
              onClick={submitChanges}
              icon={<SaveIcon />}
              label="SAVE ALL"
              size="small"
              sx={{
                borderRadius: "4px",
                height: "28px",
              }}
            />
          </>
        ) : (
          <Chip
            color="primary"
            onClick={exportPrevewDataTExcel}
            icon={<FileDownloadIcon />}
            label="EXPORT"
            size="small"
            sx={{
              borderRadius: "4px",
              height: "28px",
            }}
          />
        )}

        <Box display="flex" gap={0.5}>
          <Tooltip title={!isMaximized ? "Full screen" : "Exit Full Screen"}>
            <IconButton
              onClick={toggleMaximize}
              size="small"
              sx={{
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.04)",
                },
              }}
            >
              {isMaximized ? (
                <FullscreenExitIcon fontSize="small" />
              ) : (
                <FullscreenIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              padding: "4px",
              "&:hover": {
                backgroundColor: "#ffebee",
                color: "#d32f2f",
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AppHeader;
