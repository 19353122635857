import { MRT_ColumnDef } from "material-react-table";

interface MinMaxPreviewRowData {
  storeId: string;
  productId: string;
  onFailAction: {
    before: any;
    after: any;
  };
  maxPrice: {
    before: any;
    after: any;
  };
  minPrice: {
    before: any;
    after: any;
  };
  enabled: {
    before: any;
    after: any;
  };
}

interface MaxIncDecPreviewRowData {
  storeId: string;
  productId: string;
  onFailAction: {
    before: any;
    after: any;
  };
  maxIncrease: {
    before: any;
    after: any;
  };
  maxDecrease: {
    before: any;
    after: any;
  };
  enabled: {
    before: any;
    after: any;
  };
}

interface AutoApprovalPreviewRowData {
  storeId: string;
  productId: string;
  autoApprove: {
    before: any;
    after: any;
  };
}

const minMaxChangesPreviewTableCols: MRT_ColumnDef<MinMaxPreviewRowData>[] = [
  {
    header: "Store",
    accessorKey: "storeId",
  },
  {
    header: "Product",
    accessorKey: "productId",
    size: 150,
  },
  {
    header: "Min Price Before",
    size: 200,
    filterVariant: "text",
    accessorFn: (row) =>
      row?.minPrice?.before ? row?.minPrice?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.minPrice?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.minPrice?.before
          ? row.original?.minPrice?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Min Price After",
    size: 200,
    accessorFn: (row) => (row?.minPrice?.after ? row?.minPrice?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.minPrice?.after ? "orange" : "gray",
            fontWeight: row.original?.minPrice?.after ? "bold" : "normal",
          }}
        >
          {row.original?.minPrice?.after
            ? row.original?.minPrice?.after
            : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Max Price Before",
    size: 200,
    accessorFn: (row) =>
      row?.maxPrice?.before ? row?.maxPrice?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.maxPrice?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.maxPrice?.before
          ? row.original?.maxPrice?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Max Price After",
    size: 200,
    accessorFn: (row) => (row?.maxPrice?.after ? row?.maxPrice?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.maxPrice?.after ? "orange" : "gray",
            fontWeight: row.original?.maxPrice?.after ? "bold" : "normal",
          }}
        >
          {row.original?.maxPrice?.after
            ? row.original?.maxPrice?.after
            : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Failure Action Before",
    size: 250,
    accessorFn: (row) =>
      row?.onFailAction?.before ? row?.onFailAction?.before : "N/A",
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.onFailAction?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.onFailAction?.before
          ? row.original?.onFailAction?.before
          : "N/A"}
      </div>
    ),
  },
  {
    header: "Failure Action After",
    size: 250,
    accessorFn: (row) =>
      row?.onFailAction?.after ? row?.onFailAction?.after : "N/A",
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.onFailAction?.after ? "orange" : "gray",
            fontWeight: row.original?.onFailAction?.after ? "bold" : "normal",
          }}
        >
          {row.original?.onFailAction?.after
            ? row.original?.onFailAction?.after
            : "N/A"}
        </div>
      );
    },
  },
  {
    header: "Enabled Flag Before",
    size: 200,
    accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
    Cell: ({ row }) => (
      <div
        style={{
          display: "flex", // Flexbox for centering content
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          width: "100%", // Ensure div takes the full width of the cell
          height: "100%", // Ensure div takes the full height of the cell
          color: row.original?.enabled?.before ? "black" : "grey",
          textAlign: "center", // Center the text within the div
        }}
      >
        {row.original?.enabled?.before ? row.original?.enabled?.before : "N/A"}
      </div>
    ),
  },
  {
    header: "Enabled Flag After",
    size: 200,
    accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
    Cell: ({ row }) => {
      return (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            textAlign: "center", // Center the text within the div
            color: row.original?.enabled?.after ? "orange" : "gray",
            fontWeight: row.original?.enabled?.after ? "bold" : "normal",
          }}
        >
          {row.original?.enabled?.after ? row.original?.enabled?.after : "N/A"}
        </div>
      );
    },
  },
];

const maxIncDecChangesPreviewTableCols: MRT_ColumnDef<MaxIncDecPreviewRowData>[] =
  [
    {
      header: "Store",
      accessorKey: "storeId",
    },
    {
      header: "Product",
      accessorKey: "productId",
      size: 150,
    },
    {
      header: "Max Increase Before",
      size: 200,
      filterVariant: "text",
      accessorFn: (row) => row?.maxIncrease?.before ?? "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color:
              row.original?.maxIncrease?.before ?? false ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.maxIncrease?.before ?? "N/A"}
        </div>
      ),
    },
    {
      header: "Max Increase After",
      size: 200,
      accessorFn: (row) => row?.maxIncrease?.after ?? "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color:
                row.original?.maxIncrease?.after !== undefined &&
                row.original?.maxIncrease?.after !== null
                  ? "orange"
                  : "gray",
              fontWeight:
                row.original?.maxIncrease?.after !== undefined &&
                row.original?.maxIncrease?.after !== null
                  ? "bold"
                  : "normal",
            }}
          >
            {row.original?.maxIncrease?.after ?? "N/A"}
          </div>
        );
      },
    },
    {
      header: "Max Decrease Before",
      size: 200,
      accessorFn: (row) => row?.maxDecrease?.before ?? "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color:
              row.original?.maxDecrease?.before ?? false ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.maxDecrease?.before ?? "N/A"}
        </div>
      ),
    },
    {
      header: "Max Decrease After",
      size: 200,
      accessorFn: (row) => row?.maxDecrease?.after ?? "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color:
                row.original?.maxDecrease?.after !== undefined &&
                row.original?.maxDecrease?.after !== null
                  ? "orange"
                  : "gray",
              fontWeight:
                row.original?.maxDecrease?.after !== undefined &&
                row.original?.maxDecrease?.after !== null
                  ? "bold"
                  : "normal",
            }}
          >
            {row.original?.maxDecrease?.after ?? "N/A"}
          </div>
        );
      },
    },
    {
      header: "Failure Action Before",
      size: 250,
      accessorFn: (row) =>
        row?.onFailAction?.before ? row?.onFailAction?.before : "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.onFailAction?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.onFailAction?.before
            ? row.original?.onFailAction?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Failure Action After",
      size: 250,
      accessorFn: (row) =>
        row?.onFailAction?.after ? row?.onFailAction?.after : "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.onFailAction?.after ? "orange" : "gray",
              fontWeight: row.original?.onFailAction?.after ? "bold" : "normal",
            }}
          >
            {row.original?.onFailAction?.after
              ? row.original?.onFailAction?.after
              : "N/A"}
          </div>
        );
      },
    },
    {
      header: "Enabled Flag Before",
      size: 200,
      accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.enabled?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.enabled?.before
            ? row.original?.enabled?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Enabled Flag After",
      size: 200,
      accessorFn: (row) => (row?.enabled?.after ? row?.enabled?.after : "N/A"),
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.enabled?.after ? "orange" : "gray",
              fontWeight: row.original?.enabled?.after ? "bold" : "normal",
            }}
          >
            {row.original?.enabled?.after
              ? row.original?.enabled?.after
              : "N/A"}
          </div>
        );
      },
    },
  ];

const autoApprovalChangesPreviewTableCols: MRT_ColumnDef<AutoApprovalPreviewRowData>[] =
  [
    {
      header: "Store",
      accessorKey: "storeId",
    },
    {
      header: "Product",
      accessorKey: "productId",
      size: 150,
    },
    {
      header: "Auto Approval Flag Before",
      size: 200,
      accessorFn: (row) =>
        row?.autoApprove?.after ? row?.autoApprove?.after : "N/A",
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex", // Flexbox for centering content
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            width: "100%", // Ensure div takes the full width of the cell
            height: "100%", // Ensure div takes the full height of the cell
            color: row.original?.autoApprove?.before ? "black" : "grey",
            textAlign: "center", // Center the text within the div
          }}
        >
          {row.original?.autoApprove?.before
            ? row.original?.autoApprove?.before
            : "N/A"}
        </div>
      ),
    },
    {
      header: "Auto Approval Flag After",
      size: 200,
      accessorFn: (row) =>
        row?.autoApprove?.after ? row?.autoApprove?.after : "N/A",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex", // Flexbox for centering content
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              width: "100%", // Ensure div takes the full width of the cell
              height: "100%", // Ensure div takes the full height of the cell
              textAlign: "center", // Center the text within the div
              color: row.original?.autoApprove?.after ? "orange" : "gray",
              fontWeight: row.original?.autoApprove?.after ? "bold" : "normal",
            }}
          >
            {row.original?.autoApprove?.after
              ? row.original?.autoApprove?.after
              : "N/A"}
          </div>
        );
      },
    },
  ];
export const previewTableCols = {
  PriceFallUnderMinMax: minMaxChangesPreviewTableCols,
  MaxIncreaseDecrease: maxIncDecChangesPreviewTableCols,
  AutoApproval: autoApprovalChangesPreviewTableCols,
};
