import React, { ReactNode, useEffect, useState } from "react";
import { useGetMultiStoreSingleProductControl } from "../hooks/controlConfigApiHooks";
import { IStore } from "../interfaces/IStore";
import {
  mapAutoApproveToTableData,
  mapMaxIncDecToTableData,
  mapMinMaxPriceToTableData,
} from "../utils/mapper";
import SEBulkEditingDataGrid from "../core/SEBulkEditingDataGrid";
import {
  GridApi,
  GridRowId,
  GridRowModes,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid";
import { controlTabTableCols } from "../constants/config";
import SESkeletonLoader from "../core/SESkeletonLoader";
import useControlStore from "../store/useStoreControl";
import { AxiosError } from "axios";
import ErrorDisplay from "./ErrorDisplay";

interface DataPanelProps {
  selectedStores: string[];
  selectedProduct: string;
  children: ReactNode;
  tabId: string;
  processRowUpdate: any;
}

const DataPanel: React.FC<DataPanelProps> = ({
  children,
  selectedStores,
  selectedProduct,
  tabId,
  processRowUpdate,
}) => {
  const apiRef = useGridApiRef();
  const {
    originalMaxIncDec,
    originalMinMaxPrice,
    originalAutoApproveFlagData,
    editedMinMaxPrice,
    editedMaxIncDec,
    editedAutoApproveFlagData,
    editedMinMaxRowMeta,
    editedMaxIncDecRowMeta,
    editedAutoApprovalRowMeta,
    setMinMaxPriceData,
    setMaxIncData,
    setAutoApproveFlagData,
    updateMinMaxPrice,
    updateMaxIncDec,
    updateAutoApproval,
    globalSelectedStores,
    resetControlData,
    setInEditMode,
  } = useControlStore();

  const {
    data: fetchedData,
    error: fetchError,
    isLoading: fetchLoading,
    isFetching,
    refetch,
  } = useGetMultiStoreSingleProductControl(selectedStores, selectedProduct);

  // State for dynamic height
  const [gridHeight, setGridHeight] = useState(window.innerHeight * 0.5); // Initial height (50% of viewport)
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State for error message

  // Handle product changes
  useEffect(() => {
    const handleProductChange = async () => {
      if (selectedProduct) {
        console.log("Product changed to:", selectedProduct);
        resetControlData();
        await refetch();
      }
    };

    handleProductChange();
  }, [selectedProduct]); // Only depend on selectedProduct

  // Update store data when fetch completes
  useEffect(() => {
    if (fetchedData && !isFetching) {
      console.log("Setting new data for product:", selectedProduct);
      setMinMaxPriceData(
        fetchedData.minMaxPrice.map((data) =>
          mapMinMaxPriceToTableData(data, globalSelectedStores)
        )
      );
      setMaxIncData(
        fetchedData.maxIncDec.map((data) =>
          mapMaxIncDecToTableData(data, globalSelectedStores)
        )
      );
      setAutoApproveFlagData(
        fetchedData.autoApproveFlag.map((data) =>
          mapAutoApproveToTableData(data, globalSelectedStores)
        )
      );
    }
  }, [fetchedData, isFetching]); // Remove selectedProduct dependency

  // Show loading state while fetching
  const isLoadingData = fetchLoading || isFetching;

  // Handle fetch errors
  useEffect(() => {
    if (fetchError) {
      console.error(
        "Error fetching data for product:",
        selectedProduct,
        fetchError
      );
      if (fetchError instanceof AxiosError) {
        // @ts-ignore
        const message =
          fetchError.response?.data?.message ||
          fetchError.message ||
          "An error occurred";
        setErrorMessage(message); // Set the error message
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  }, [fetchError, selectedProduct]);

  const [rowModesModel, setRowModesModel] = useState({});

  // Function to update grid height based on window size
  const updateGridHeight = () => {
    console.log("height -- ", window.innerHeight * 0.5);

    const newHeight = Math.min(window.innerHeight * 0.5, 470); // Minimum height of 300px
    setGridHeight(newHeight);
  };

  useEffect(() => {
    // Set initial height
    updateGridHeight();

    // Add resize listener
    window.addEventListener("resize", updateGridHeight);

    // Cleanup
    return () => window.removeEventListener("resize", updateGridHeight);
  }, []);

  const getControlData = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return editedMinMaxPrice;
    } else if (tabId === "maxIncDec") {
      return editedMaxIncDec;
    } else if (tabId === "autoApproval") {
      return editedAutoApproveFlagData;
    }
    return [];
  };

  const getSelectedRows = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta);
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta);
    } else if (tabId === "autoApproval") {
      return Object.keys(editedAutoApprovalRowMeta);
    }
    return [];
  };

  const getControlDataOriginalRow = (tabId: string, id: string) => {
    if (tabId === "minMaxPrice") {
      return originalMinMaxPrice.find((val) => val.id === id);
    } else if (tabId === "maxIncDec") {
      return originalMaxIncDec.find((val) => val.id === id);
    } else {
      return originalAutoApproveFlagData.find((val) => val.id === id);
    }
  };

  const handleRowEditClick = React.useCallback(
    (id: GridRowId) => {
      console.log(tabId);
      setInEditMode(true, tabId);
      // Get the row data
      const row = apiRef.current.getRow(id);
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.Edit },
        },
      });
    },
    [tabId, rowModesModel]
  );

  const handleRowEditSave = React.useCallback(
    (id: GridRowId) => {
      setInEditMode(false, tabId);
      // Stop editing the row and save changes
      setRowModesModel({
        ...rowModesModel,
        [tabId]: {
          ...rowModesModel[tabId],
          [id]: { mode: GridRowModes.View },
        },
      });
      const row = apiRef.current.getRow(id);
      processRowUpdate(row);
      // Optionally trigger a save action here if needed
    },
    [apiRef, processRowUpdate, tabId, rowModesModel]
  );

  const handleDiscardButtonClick = React.useCallback(
    (id) => {
      const originalRow = getControlDataOriginalRow(tabId, id);
      if (tabId === "minMaxPrice") {
        updateMinMaxPrice(id, originalRow, []);
      } else if (tabId === "maxIncDec") {
        updateMaxIncDec(id, originalRow, []);
      } else {
        updateAutoApproval(id, originalRow, []);
      }
    },
    [tabId, editedMinMaxPrice, editedMaxIncDec, editedAutoApproveFlagData]
  );

  const onRowSelectionToggle = React.useCallback(
    (newSelection: GridRowSelectionModel) => {
      //   const tabId = validationTabs[validationTabValue].id;
      //   const selectedIds = newSelection;
      //   const previouslySelectedIds = selectedRows[tabId] || [];
      //   const removedIds = previouslySelectedIds.filter(
      //     (id) => !selectedIds.includes(id)
      //   );
      //   removedIds.forEach((id) => handleDiscardButtonClick(id));
    },
    []
  );

  const getRowClassName = React.useCallback(
    ({ id }) => {
      if (tabId === "minMaxPrice") {
        return editedMinMaxRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "maxIncDec") {
        return editedMaxIncDecRowMeta[id] ? "row--edited" : "";
      } else if (tabId === "autoApproval") {
        return editedAutoApprovalRowMeta[id] ? "row--edited" : "";
      }
    },
    [
      tabId,
      editedMinMaxRowMeta,
      editedMaxIncDecRowMeta,
      editedAutoApprovalRowMeta,
    ]
  );

  const getUnsavedRowIds = (tabId: string) => {
    if (tabId === "minMaxPrice") {
      return Object.keys(editedMinMaxRowMeta);
    } else if (tabId === "maxIncDec") {
      return Object.keys(editedMaxIncDecRowMeta);
    } else if (tabId === "autoApproval") {
      return Object.keys(editedAutoApprovalRowMeta);
    }
    return [];
  };

  const getCellClassName = (params) => {
    const { id, field } = params;
    if (tabId === "minMaxPrice") {
      return editedMinMaxRowMeta?.[id] &&
        editedMinMaxRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId == "maxIncDec") {
      return editedMaxIncDecRowMeta?.[id] &&
        editedMaxIncDecRowMeta[id].includes(field)
        ? "cell--edited"
        : "";
    } else if (tabId == "autoApproval") {
      return editedAutoApprovalRowMeta?.[id] &&
        editedAutoApprovalRowMeta[id]?.includes(field)
        ? "cell--edited"
        : "";
    }
    return "";
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <>
      {children}
      <SESkeletonLoader
        isLoading={isLoadingData}
        variant="rectangular"
        width="100%"
        height={90}
      >
        {fetchError ? (
          <ErrorDisplay
            message={errorMessage || "An unexpected error occurred."}
            onRetry={refetch}
          />
        ) : (
          <div style={{ height: gridHeight }}>
            <SEBulkEditingDataGrid
              apiRef={apiRef}
              cols={controlTabTableCols[tabId]}
              rows={getControlData(tabId)}
              unsavedRows={getUnsavedRowIds(tabId)}
              isSaving={isLoadingData}
              processRowUpdate={processRowUpdate}
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
              handleEditClick={handleRowEditClick}
              handleSaveClick={handleRowEditSave}
              rowModesModel={rowModesModel?.[tabId] || {}}
              setRowModesModel={setRowModesModel}
              selectedRows={getSelectedRows(tabId)}
              handleDiscardButtonClick={handleDiscardButtonClick}
              onRowSelectionToggle={onRowSelectionToggle}
              toolbar={CustomToolbar}
            />
          </div>
        )}
      </SESkeletonLoader>
    </>
  );
};

export default DataPanel;
